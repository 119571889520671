<template>
  <div id="stopOrder">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="orderId" label="订单号" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column
          prop="serviceStartTime"
          label="停车入场时间"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="serviceEndTime"
          label="停车出场时间"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="couponName" label="优惠券名称" align="center">
        </el-table-column>
        <el-table-column prop="totalPrice" label="订单金额" align="center">
        </el-table-column>
        <el-table-column prop="payAmount" label="实付金额" align="center">
        </el-table-column>
        <el-table-column prop="useValue" label="优惠券抵用金额" align="center">
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
        startTime: "",
        endTime: "",
      },

      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate = {
        keyword: "",
        startTime: "",
        endTime: "",
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/coupon/list/orders", {
          merchantId: this.$route.params.id,
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.serviceStartTime = this.$moment(item.serviceStartTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.serviceEndTime = this.$moment(item.serviceEndTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.payTime = this.$moment(item.payTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#stopOrder {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addCoupon {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
